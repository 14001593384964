import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import Vuesax from 'vuesax'
import router from './router'
import store from './store'
import './assets/style.css'

Vue.config.productionTip = false


window.axios = require('axios');

//window.axios.defaults.headers.common['Authorization'] = 'Bearer JDJ5JDEwJGh0UGJTSGw1anRRWjdWSXRvZGJ4S09yQnZpeGdRRncuREpURThYWUVqbVNMM2VTWlFMNGll';
window.axios.defaults.headers.common['Content-Type'] = 'application/json';

import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax, {
    // options here
})
Vue.use(Vuex)


new Vue({
    render: h => h(App),
    router,
    store,
}).$mount('#app')
