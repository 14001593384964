<template>
  <div class="center">
    <vs-navbar shadow square center-collapsed>



      <template #right>
        <vs-button @click="Logout">Çıkış Yap</vs-button>
      </template>
    </vs-navbar>

  </div>
</template>


<script>
export default {
  methods: {
    Logout() {
      this.$store.dispatch("logout")
    }
  }
}
</script>

<style scoped>

</style>
