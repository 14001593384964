<template>
  <div class="center">
    <vs-row class="mb-10">

      <vs-col w="6" class="mt-2">
        Projeler
      </vs-col>
      <vs-col w="6">
        <div class="float-right">
          <vs-button flat to="/project/new">
            <i class="bx bx-plus"></i> Yeni Proje
          </vs-button>
        </div>
      </vs-col>


    </vs-row>


    <vs-table>
      <template #header>
        <vs-input border placeholder="Arama"/>
      </template>
      <template #thead>
        <vs-tr>
          <vs-th sort @click="projects = $vs.sortData($event ,projects, 'id')">
            #
          </vs-th>
          <vs-th sort @click="projects = $vs.sortData($event ,projects, 'Account_id')">
            Proje Adı
          </vs-th>
          <vs-th sort @click="projects = $vs.sortData($event ,projects, 'Account_id')">
            Açıklama
          </vs-th>
          <vs-th sort @click="projects = $vs.sortData($event ,projects, 'Account_id')">
            Oluşturma Tarihi
          </vs-th>
          <vs-th sort @click="projects = $vs.sortData($event ,projects, 'Account_id')">
            Bitiş Tarihi
          </vs-th>
          <vs-th sort @click="projects = $vs.sortData($event ,projects, 'Account_id')">
            Günlük Çalışma Süresi
          </vs-th>
          <vs-th sort @click="projects = $vs.sortData($event ,projects, 'Account_id')">
            Proje Durumu
          </vs-th>
          <vs-th style="width: 120px">

          </vs-th>
        </vs-tr>
      </template>
      <template #tbody>
        <vs-tr
            :key="i"
            v-for="(tr, i) in projects"
            :data="tr"
        >
          <vs-td>
            {{ tr.id }}
          </vs-td>
          <vs-td>
            {{ tr.name }}
          </vs-td>
          <vs-td>
            {{ tr.description }}
          </vs-td>
          <vs-td>
            {{ tr.history }}
          </vs-td>
          <vs-td>
            {{ tr.history_end }}
          </vs-td>
          <vs-td>
            {{ tr.times + ' saat' }}
          </vs-td>
          <vs-td>
            <vs-button warn gradient v-if="tr.status==0">Pasif</vs-button>
            <vs-button success gradient v-if="tr.status==1">Aktif</vs-button>
             <vs-button danger gradient v-if="tr.status==2">Geçiken</vs-button>
            <vs-button primary gradient v-if="tr.status==3">Tamamlanan</vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>


  </div>
</template>


<script>


export default {
  data: () => ({
    projects: {},
    formLabelWidth: '180px',

  }),
  methods: {
    Query() {
      const loading = this.$vs.loading({
        type: 'corners',
      })
      this.$store.dispatch("Query", {method: "get", url: "project/all"})
          .then(response => {
            this.projects = response.Data;
            loading.close()
          });
    }
  },
  created() {
    this.Query();
  }
}
</script>

<style scoped>

</style>
