import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {router} from "./router";

Vue.use(Vuex)
//let ApiUrl = "http://localhost/project/"
let ApiUrl = "https://papi.mestav.com.tr/"

const store = new Vuex.Store({
    namespaced: true,
    state: {
        Account_id: localStorage.getItem('Account_id'),
        Personal: localStorage.getItem('_Personal'),
        Token: localStorage.getItem('_Token'),
    },
    mutations: {
        setToken(state, data) {
            if (data.Token) state.Token = data.Token
            if (data.Account_id) state.Account_id = data.Account_id
        },
    },
    actions: {
        initAuth({commit}) {
            let Token = localStorage.getItem("_Token");
            let Account = localStorage.getItem("_Account");
            let Account_id = localStorage.getItem("Account_id");
            if (!Token) {
                if (location.pathname[1] + location.pathname[2] + location.pathname[3] + location.pathname[4] + location.pathname[5] != 'giris') {
                    router.push("/giris");
                    return false
                }
            } else {
                commit("setToken", {
                    Token: Token,
                    Account: Account,
                    Account_id: Account_id
                })
            }
        },
        Query({state}, params) {
            var BaseUrl = null;
            if (state.Account_id) BaseUrl = ApiUrl + params.url; else BaseUrl = ApiUrl + params.url;
            return axios({
                method: params.method,
                headers: {
                    "Account_id": state.Account_id,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Cache-Control': 'no-cache',
                    'Authorization': state.Token ? 'Bearer ' + state.Token : ''
                },
                url: BaseUrl,
                data: params.data,
            }).then(response => {
                return response.data;
            }).catch(function (error) {
                if (error.response.status == 401) {
                    localStorage.clear();
                    location.reload();
                    router.push("/login");
                }

            })
        },

        logout() {
            localStorage.clear();
            location.reload();
            router.push("/login");
        },

        sessions({commit}, data) {
            commit('setToken', data)
        }

    },

    getters: {
        Session(state) {
            return !!state.Token;
        },
    },
})

export default store
