<template>
  <div class="center">
    <vs-row class="mb-10">

      <vs-col w="6" class="mt-2">
        Personel
      </vs-col>
      <vs-col w="6">
        <div class="float-right">
          <vs-button flat to="/personal/new">
            <i class="bx bx-plus"></i> Yeni Personel
          </vs-button>
        </div>
      </vs-col>


    </vs-row>


    <vs-table>
      <template #header>
        <vs-input border placeholder="Arama"/>
      </template>
      <template #thead>
        <vs-tr>
          <vs-th sort @click="projects = $vs.sortData($event ,projects, 'id')">
            #
          </vs-th>
          <vs-th sort @click="projects = $vs.sortData($event ,projects, 'Account_id')">
            Adı Soyadı
          </vs-th>
          <vs-th sort @click="projects = $vs.sortData($event ,projects, 'Account_id')">
            Telefon
          </vs-th>
          <vs-th sort @click="projects = $vs.sortData($event ,projects, 'Account_id')">
            Email
          </vs-th>

        </vs-tr>
      </template>
      <template #tbody>
        <vs-tr
            :key="i"
            v-for="(tr, i) in projects"
            :data="tr"
        >
          <vs-td>
            {{ tr.id }}
          </vs-td>
          <vs-td>
            {{ tr.name + ' ' + tr.surname }}
          </vs-td>
          <vs-td>
            {{ tr.phone }}
          </vs-td>
          <vs-td>
            {{ tr.email }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>


  </div>
</template>


<script>


export default {
  data: () => ({
    projects: {},
    formLabelWidth: '180px',

  }),
  methods: {
    Query() {
      const loading = this.$vs.loading({
        type: 'corners',
      })
      this.$store.dispatch("Query", {method: "get", url: "personal"})
          .then(response => {
            this.projects = response.Data;
            loading.close()
          });
    }
  },
  created() {
    this.Query();
  }
}
</script>

<style scoped>

</style>
