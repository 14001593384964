<template>
  <div>
    <vs-sidebar
        absolute
        reduce
        open
        background="primary"
        textWhite
        v-model="active"
    >
      <template #logo>
        <img src="../assets/logo.svg" alt="Logo" width="90">
      </template>
      <vs-sidebar-item id="panel" to="/" :class="[this.$route.path=='/panel' || this.$route.path=='/'?'active':'']">
        <template #icon>
          <i class='bx bx-home'></i>
        </template>
        Panel
      </vs-sidebar-item>
      <vs-sidebar-item id="project" to="/project" :class="[this.$route.path=='/project'?'active':'']">
        <template #icon>
          <i class='bx bx-grid-alt'></i>
        </template>
        Projeler
      </vs-sidebar-item>
      <vs-sidebar-item id="personal" to="/personal" :class="[this.$route.path=='/personal'?'active':'']">
        <template #icon>
          <i class='bx bx-group'></i>
        </template>
        Kullanıcılar
      </vs-sidebar-item>

      <!--vs-sidebar-item id="boards" to="/boards" :class="[this.$route.path=='/boards'?'active':'']">
        <template #icon>
          <i class='bx bx-group'></i>
        </template>
        Boards
      </vs-sidebar-item-->


      <template #footer>
        <vs-row justify="space-between">

          <vs-avatar>
            <img src="../assets/logo-blue.svg" alt="">
          </vs-avatar>
        </vs-row>
      </template>
    </vs-sidebar>
  </div>
</template>
<script>
export default {
  data: () => ({
    active: 'home',
  })
}
</script>


<style>

.vs-sidebar__item__text-tooltip {
  color: #ffffff;
  background: #2d3f52;
  width: auto !important;
  min-width: 100%;
  padding: 5px;
  border-radius: 0 10px 10px 0;
  font-size: 12px;
}

.vs-sidebar-content.reduce .vs-sidebar__item:hover .vs-sidebar__item__text-tooltip {
  left: 50px !important;
}


</style>
