<template>
  <vs-row vs-justify="center" style="align-items: center;">
    <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="8" class="login-image">
      <img src="../assets/login.svg" alt="">
    </vs-col>
    <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="4" class="login-form">
      <vs-card type="6" style="max-width: 350px;">
        <template #title>
          <h4 class="not-margin">
            Giriş Yap
          </h4>
        </template>
        <template #img>
          <img src="../assets/logo-blue.svg" alt="" class="mb-5" style="width:180px">
        </template>
        <template #text>
          <div class="con-form">
            <vs-input placeholder="Email" v-model="Form.email">
              <template #icon>
                @
              </template>
            </vs-input>
            <vs-input type="password" placeholder="Şifre" v-model="Form.password">
              <template #icon>
                <i class='bx bxs-lock'></i>
              </template>
            </vs-input>
            <div class="flex">
              <a href="#">Şifremi Unuttum ?</a>
            </div>
            <br>
            <vs-button @click="Login()">
              Giriş Yap
            </vs-button>
          </div>

        </template>
      </vs-card>
    </vs-col>
  </vs-row>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      Form: {
        email: "",
        password: ""
      }
    };
  },
  methods: {
    Notification(title, text, type = 'success') {
      this.$vs.notification({
        progress: 'auto',
        color: type,
        position: 'top-right',
        title: title,
        text: text
      })
    },
    Login() {
      if (this.Form.email != '' && this.Form.password != '') {
        this.$store.dispatch("Query", {method: "post", url: "auth/login", data: this.Form})
            .then(response => {
              if (response.Status == 'OK') {
                localStorage.setItem('_Token', response.Token);
                localStorage.setItem('_Personal', JSON.stringify(response.Personal));
                localStorage.setItem('Account_id', response.Account_id);
                this.$store.dispatch("sessions", {
                  Token: response.Token,
                  Personal: response.Personal,
                  Account_id: response.Account_id
                })
                this.Notification('Başarılı', 'Giriş Başarılı.');
                this.$router.push("/panel");
              }
              if (response.Status == 'NO') {
                if (response.Error.email == '101') this.Notification('Başarısız', 'Email veya Şifre Yanlış.', "danger");
                if (response.Error.password == '101') this.Notification('Başarısız', 'Email veya Şifre Yanlış.', "danger");
              }
            });
      } else {
        this.Notification('Hata', 'Email ve Şifre giriniz.', "danger");
      }
    },
  },
}
</script>

<style scoped>
img {
  align-items: center;
  vertical-align: center;
}

.vs-card {
  vertical-align: middle;
  padding: 1rem;
}

.login-image {
  vertical-align: middle;
  height: auto;
  text-align: center;
  display: flex;

}

.login-form > .vs-card {
  height: 100vh;
}

.login-image img {
  max-width: 100%;
  vertical-align: middle;
}

#app {
  padding: 0 !important;
  height: 100% !important;
}

.vs-row {
  height: 100vh;
}
</style>
