<script
    src="../../../../Downloads/paul-themes.com/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script>
<template>
  <div class="row">
    <div class="col-2">
      <h3>Açık</h3>
      <draggable class="list-group" :list="list1" group="people" @change="log">
        <div
          class="list-group-item"
          v-for="(element, index) in list1"
          :key="element.name"
        >
           <task-card
              :task="element"
              class="mt-3 cursor-move"
            >
           </task-card>

          <b>{{ element.id }}</b> {{ element.name }} {{ index }}
        </div>
      </draggable>
    </div>

    <div class="col-2">
      <h3>To Do</h3>
      <draggable class="list-group" :list="list2" group="people" @change="log">
        <div
          class="list-group-item"
          v-for="(element, index) in list2"
          :key="element.name"
        >
          {{ element.name }} {{ index }}
        </div>
      </draggable>
    </div>


    <div class="col-2">
      <h3>In Progress</h3>
      <draggable class="list-group" :list="list3" group="people" @change="log">
        <div
          class="list-group-item"
          v-for="(element, index) in list3"
          :key="element.name"
        >
          {{ element.name }} {{ index }}
        </div>
      </draggable>
    </div>


    <div class="col-2">
      <h3>Committed</h3>
      <draggable class="list-group" :list="list4" group="people" @change="log">
        <div
          class="list-group-item"
          v-for="(element, index) in list4"
          :key="element.name"
        >
          {{ element.name }} {{ index }}
        </div>
      </draggable>
    </div>


    <div class="col-2">
      <h3>Done</h3>
      <draggable class="list-group" :list="list5" group="people" @change="log">
        <div
          class="list-group-item"
          v-for="(element, index) in list5"
          :key="element.name"
        >
          {{ element.name }} {{ index }}
        </div>
      </draggable>
    </div>


  </div>
</template>
<script>
import draggable from "vuedraggable";
import TaskCard from "./components/TaskCard.vue";
export default {
  name: "two-lists",
  display: "Two Lists",
  order: 1,
  components: {
    draggable,
    TaskCard,
  },
  data() {
    return {
      list1: [
        { name: "User View Aircraft Logs", id: 1 },
        { name: "User View Aircraft on Map", id: 2 },
      ],
      list2: [
        { name: "Juan", id: 5 },
        { name: "Edgard", id: 6 },
        { name: "Johnson", id: 7 }
      ],
      list3: [],
      list4: [],
      list5: []
    };
  },
  methods: {
    add: function() {
      this.list.push({ name: "Juan" });
    },
    replace: function() {
      this.list = [{ name: "Edgard" }];
    },
    clone: function(el) {
      return {
        name: el.name + " cloned"
      };
    },
    log: function(evt) {
      window.console.log(evt);
    }
  }
};
</script>
