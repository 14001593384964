<template>
  <div class="center">
    <div class="container-fluid">
      <div class="col-sm-6">
        Panel
      </div>
      <div class="col-sm-6">
        <div class="float-right">
        </div>
      </div>

      <div class="row mt-10">
        <div class="col-sm-3 m-0 pr-0">
          <div class="card">
            <div class="row ">
              <div class="col-sm-6">
                <div class="float-left" style="position: relative; left: 0; font-size: 60px;color: #ecebec;">
                  <b>{{ Passive }}</b>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-right" style="position: relative; ">
                  <i class="bx bx-block" style="font-size: 65px;color: #ecebec;"></i>
                </div>
              </div>
            </div>
            <div class="card-header d-flex align-items-center"
                 style="background: #ecebec; width: 100%; height: 40px; color: #ffffff; padding: 10px;margin-top: -21px; border-radius: 5px 0px 0px 5px;">
              Pasif Projeler
            </div>
          </div>
        </div>

        <div class="col-sm-3 m-0 pr-0 pl-0">
          <div class="card">
            <div class="row ">
              <div class="col-sm-6">
                <div class="float-left"
                     style="position: relative; left: 0; font-size: 60px;color: #ecebec; margin-left: 15px">
                  <b>{{ Active }}</b>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-right" style="position: relative; ">
                  <i class="bx bxs-factory" style="font-size: 65px;color: #f7b22c;"></i>
                </div>
              </div>
            </div>
            <div class="card-header d-flex align-items-center"
                 style="background: #f7b22c; width: 100%; height: 40px; color: #ffffff; padding: 10px;margin-top: -21px;">
              Aktif Projeler
            </div>
          </div>
        </div>

        <div class="col-sm-3 m-0 pr-0 pl-0">
          <div class="card">
            <div class="row ">
              <div class="col-sm-6">
                <div class="float-left"
                     style="position: relative; left: 0; font-size: 60px;color: #ecebec; margin-left: 15px">
                  <b>{{ Time }}</b>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-right" style="position: relative; ">
                  <i class="bx bxs-alarm-exclamation" style="font-size: 65px;color: #df2415;"></i>

                </div>
              </div>
            </div>
            <div class="card-header d-flex align-items-center"
                 style="background: #df2415; width: 100%; height: 40px; color: #ffffff; padding: 10px;margin-top: -21px;">
              Geciken Projeler
            </div>
          </div>
        </div>

        <div class="col-sm-3 m-0 pl-0">
          <div class="card">
            <div class="row ">
              <div class="col-sm-6">
                <div class="float-left"
                     style="position: relative; left: 0; font-size: 60px;color: #ecebec; margin-left: 15px">
                  <b>{{ Success }}</b>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-right" style="position: relative; ">
                  <i class="bx bx-check" style="font-size: 65px;color: #3da936;"></i>
                </div>
              </div>
            </div>
            <div class="card-header d-flex align-items-center"
                 style="background: #3da936; width: 100%; height: 40px; color: #ffffff; padding: 10px;margin-top: -21px; border-radius: 0px 5px 5px 0px;">
              Tamamlanan Projeler
            </div>
          </div>
        </div>

      </div>

      <vs-row class="mb-10 mt-10" style="margin-top: 30px">


        <div class="col-sm-3 m-0 pl-0">
          <div class="card" style="background: #3da936; border-radius: 15px 15px;">
            <div class="row ">
              <div class="col-sm-6">
                <div class="float-left"
                     style="position: relative; left: 0; font-size: 60px;color: #ecebec; padding: 10px;">
                  {{ formattedElapsedTime }}
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-right" style="position: relative; ">
                  <i class="bx bxs-alarm" style="font-size: 115px;color: #ecebec; opacity: 0.2"></i>
                </div>
              </div>
            </div>
            <div class="card-header d-flex align-items-center"
                 style="background: #3da936; width: 100%; height: 40px; color: #ffffff; padding: 10px;">
              Aktivite İşleyiş Süresi
            </div>
          </div>
          <div class="card" style="background: #ecebec; border-top: 2px solid #ffffff; ">
            <div class="card-header d-flex align-items-center"
                 style="background: #999; width: 100%; color: #ffffff; padding: 10px;">
              <span style="font-size: 25px; font-weight: bold; margin-right: 10px">%0</span> Günlük Proje Çalışma Süresi
            </div>
          </div>


          <div class="card" style="background: #ecebec; border-top: 2px solid #ffffff; ">
            <div class="card-header d-flex align-items-center"
                 style="background: #999; width: 100%; color: #ffffff; padding: 10px;">


              <vs-select
                  :key="Project.length"
                  class="selectExample float-righ"
                  placeholder="Proje Seçiniz"
                  v-model="Form.Project_id"
                  @change="Information()"
                  :disabled="projectdisabled"
              >
                <vs-option label="Seçiniz" value="0">
                  Seçiniz
                </vs-option>
                <vs-option v-for="(item,index) in Project" :key="index" :value="item.id"
                           :label="item.name">
                  {{ item.name }}
                </vs-option>
              </vs-select>

              <vs-select
                  class="selectExample float-left ml-5"
                  placeholder="Tip Seçiniz"
                  v-model="Form.Type"
                  @change="Menager()"
                  :disabled="typedisabled"
              >
                <vs-option label="Proje Çalışması" value="2">
                  Proje Çalışması
                </vs-option>
                <vs-option label="Telefon Görüşmesi" value="1">
                  Telefon Görüşmesi
                </vs-option>
                <vs-option label="Toplantı" value="3">
                  Toplantı
                </vs-option>
              </vs-select>

            </div>
          </div>
          <div class="card"
               style="background: #ecebec; border-top: 2px solid #ffffff;text-align: center; align-items: center; ">
            <div class="card-header d-flex align-items-center"
                 style="background: #999; width: 100%; color: #ffffff; padding: 10px; text-align: center; align-items: center;">
              <vs-button success @click="start()" :disabled="startdisabled">Başlat</vs-button>
              <vs-button danger @click="stop()" :disabled="stopdisabled">Durdur</vs-button>
            </div>
          </div>

          <div class="card" style="background: #df2415; border-radius: 0px 0px 15px 15px;">
            <div class="row ">
              <div class="col-sm-6">
                <div class="float-left"
                     style="position: relative; left: 0; font-size: 60px;color: #ecebec; padding: 10px;">
                  %0
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-right" style="position: relative; ">
                  <i class="bx bx-code-alt" style="font-size: 115px;color: #ecebec; opacity: 0.2"></i>
                </div>
              </div>
            </div>
            <div class="card-header d-flex align-items-center"
                 style=" width: 100%; height: 40px; color: #ffffff; padding: 10px;">
              {{ ProjectName }}
            </div>
          </div>

        </div>


        <vs-col w="9">
          <div class="text-center"><h2>Etkinlik Geçmişi</h2></div>


          <vs-table>
            <template #header>
              <vs-input border placeholder="Arama"/>
            </template>
            <template #thead>
              <vs-tr>
                <vs-th sort @click="Data = $vs.sortData($event ,Data, 'id')" style="width: 90px">
                  #
                </vs-th>
                <vs-th sort @click="Data = $vs.sortData($event ,Data, 'history')">
                  Başlangıç Tarihi
                </vs-th>
                <vs-th sort @click="Data = $vs.sortData($event ,Data, 'history')">
                  Bitiş Tarihi
                </vs-th>
                <vs-th sort @click="Data = $vs.sortData($event ,Data, 'history')">
                  Süre
                </vs-th>
                <vs-th sort @click="Data = $vs.sortData($event ,Data, 'name')">
                  Tip
                </vs-th>
                <vs-th sort @click="Data = $vs.sortData($event ,Data, 'name')">
                  Açıklama
                </vs-th>
                <vs-th sort @click="Data = $vs.sortData($event ,Data, 'name')">
                  Durum
                </vs-th>
                <vs-th></vs-th>
              </vs-tr>
            </template>
            <template #tbody>
              <vs-tr
                  :key="i"
                  v-for="(tr, i) in Data"
                  :data="tr"
              >
                <vs-td>
                  {{ tr.id }}
                </vs-td>
                <vs-td>
                  {{ tr.history }}
                </vs-td>
                <vs-td>
                  {{ tr.history_end }}
                </vs-td>
                <vs-td>
                  {{ tr.total }}
                </vs-td>
                <vs-td>
                  {{ Type(tr.type) }}
                  <template>
                    <b>{{ (tr.Project ? ' (' + tr.Project + ')' : '') }}</b>
                  </template>
                </vs-td>
                <vs-td>
                  {{ tr.description }}
                </vs-td>
                <vs-td>
                  {{ (tr.status == 1 ? 'Aktif' : 'Pasif') }}
                </vs-td>

                <vs-td>
                  <div class="center" style="min-width: 100px">
                    <vs-button border active class="float-left" @click="Delete(tr.id)"><i class='bx bx-trash'></i>
                    </vs-button>
                    <!--vs-button border active class="float-left" @click="ClientEdit(tr)"><i class='bx bx-edit'></i>
                    </vs-button-->
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>


export default {

  name: "panel",
  data: () => ({
    Form: {
      Project_id: '0',
      Type: '2',
      history_id: '',
    },
    Data: [],
    projectdisabled: false,
    startdisabled: true,
    stopdisabled: true,
    typedisabled: true,
    clients: [],
    Project: [],
    ProjectName: 'Proje Seçiniz',
    time: '00:00:00',
    elapsedTime: 0,
    timer: undefined,
    Passive: 0,
    Active: 0,
    Time: 0,
    Success: 0,
    running: false,
  }),
  methods: {
    Notification(title, text, type = 'success') {
      this.$vs.notification({
        progress: 'auto',
        color: type,
        position: 'top-right',
        title: title,
        text: text
      })
    },

    Projects() {
      const loading = this.$vs.loading({
        type: 'corners',
      })
      this.$store.dispatch("Query", {method: "get", url: "project"})
          .then(response => {
            this.Project = response.Data;
            this.Passive = response.passive;
            this.Active = response.active;
            this.Time = response.time;
            this.Success = response.success;
            loading.close()
          });
    },
    History() {

      this.$store.dispatch("Query", {method: "get", url: "history"})
          .then(response => {
            console.log(response)
            this.Data = response.Data;
            if (response.Stopwatch) {
              this.Form.history_id = response.Stopwatch_id
              this.startdisabled = true;
              this.stopdisabled = false;
              this.projectdisabled = true;
              this.typedisabled = true;
              this.Form.Project_id = response.Project_id
              this.Form.Type = response.type
              //let date = new Date(response.Stopwatch);
              //localStorage.setItem("Stopwatch", response.Stopwatch);
              //var Id = localStorage.getItem("History_id");
              this.ResumeTime();

            }
          });
    },
    Information() {
      if (this.Form.Project_id > 0) {
        this.startdisabled = false;
        this.typedisabled = false;
      } else {
        this.startdisabled = true;
        this.typedisabled = true;
        this.ProjectName = "Proje Seçiniz";
      }
      for (let i = 0; i < this.Project.length; i++) {
        if (this.Project[i].id == this.Form.Project_id) {
          this.ProjectName = this.Project[i].name
        }
      }
    },


    start() {
      this.running = true;
      this.startdisabled = false;
      this.stopdisabled = true;
      this.typedisabled = true;
      this.$store.dispatch("Query", {method: "post", url: "history", data: this.Form})
          .then(response => {
            if (response.Status == 'OK') {

              this.Form.history_id = response.id;
              this.Notification('Başarılı', 'İşlem başarıyla gerçekleştirildi.');
              this.startdisabled = true
              this.History();
              let date = new Date();
              var Stopwatch = localStorage.getItem("Stopwatch");
              if (Stopwatch) {
                var old = new Date(Math.abs((Stopwatch)));

                var timeDiff = Math.abs(old.getTime() - date.getTime()); //İki tarihin
                //var diffSecs = Math.ceil(timeDiff  / (1000 * 3600 * 24)); //Sonuç
                this.elapsedTime = timeDiff;
                console.log(timeDiff);
              } else {
                localStorage.setItem("Stopwatch", date.getTime());
              }


              this.StartTime();


            }
          });
      //this.Notification("Hata", "Lütfen proje seçiniz", "danger")
    },
    stop() {
      localStorage.removeItem("History_id");
      this.$store.dispatch("Query", {method: "put", url: "history", data: this.Form})
          .then(response => {
            console.log(response)
            if (response.Status == 'OK') {
              localStorage.removeItem("Stopwatch");
              clearInterval(this.time);
              this.startdisabled = false;
              this.stopdisabled = true;
              this.typedisabled = false;
              this.projectdisabled = false;

              this.running = false
              this.elapsedTime = 0;
              this.History();
              this.Notification('Başarılı', 'İşlem başarıyla gerçekleştirildi.');

            }
          });
    },
    ResumeTime() {
      let date = new Date();
      var Stopwatch = localStorage.getItem("Stopwatch");
      if (Stopwatch) {
        var old = new Date(Math.abs((Stopwatch)));

        var timeDiff = Math.abs(old.getTime() - date.getTime()); //İki tarihin
        //var diffSecs = Math.ceil(timeDiff  / (1000 * 3600 * 24)); //Sonuç
        this.elapsedTime = timeDiff;
        console.log(timeDiff);
      } else {
        localStorage.setItem("Stopwatch", date.getTime());
      }


      if (this.running == false) this.StartTime();
    },
    StartTime() {
      this.time = setInterval(() => {
        this.elapsedTime += 1000;
      }, 1000);
    },
    reset() {
      this.elapsedTime = 0;
    },
    Menager() {

    },
    Type(id) {
      if (id == 1) return 'Telefon Görüşmesi';
      if (id == 2) return 'Proje Çalışması';
      if (id == 3) return 'Toplantı';
    },
    Delete(id) {
      this.$store.dispatch("Query", {method: "delete", url: "history/" + id})
          .then(response => {
            if (response.Status == 'OK') {
              this.History();
              this.Notification('Başarılı', 'İşlem başarıyla gerçekleştirildi.');
            }
          });
    },
  },
  computed: {
    formattedElapsedTime() {
      const date = new Date(null);
      date.setSeconds(this.elapsedTime / 1000);
      const utc = date.toUTCString();
      return utc.substr(utc.indexOf(":") - 2, 8);
    }
  },
  created() {

    console.log(new Date().getTime())
    console.log(new Date(("2021-10-01 06:31:58")).getTime())
    this.Projects();
    this.History();
  }
}
</script>

<style scoped>

</style>



