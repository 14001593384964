import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

import login from '@/Module/login'
import Panel from '@/Module/panel'
import Project from '@/Module/project'
import Personal from '@/Module/personal'
import boards from '@/Module/boards'


Vue.use(Router)
export default new Router({
    routes: [
        {
            path: "/login",
            component: login,
            beforeEnter(to, from, next) {
                if (store.getters.Session) {
                    next("/panel");
                } else {
                    next();
                }
            }
        },
        {
            path: '/',
            component: Panel,
            beforeEnter(to, from, next) {
                if (store.getters.Session) {
                    next();
                } else {
                    next('/login');

                }
            }
        },
        {
            path: '/panel',
            component: Panel,
            beforeEnter(to, from, next) {
                if (store.getters.Session) {
                    next();
                } else {
                    next('/login');

                }
            }
        },
        {
            path: '/boards',
            component: boards,
            beforeEnter(to, from, next) {
                if (store.getters.Session) {
                    next();
                } else {
                    next('/login');

                }
            }
        },
        {
            path: '/project',
            component: Project,
            beforeEnter(to, from, next) {
                if (store.getters.Session) {
                    next();
                } else {
                    next('/login');

                }
            }
        },
        {
            path: '/personal',
            component: Personal,
            beforeEnter(to, from, next) {
                if (store.getters.Session) {
                    next();
                } else {
                    next('/login');

                }
            }
        },
    ],
    mode: "history",
    linkActiveClass: "active",
    linkExactActiveClass: "active"
})
