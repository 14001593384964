<template>
  <div style="width: 100%; height: auto">
    <Header v-if="this.$route.path!='/login'"></Header>
    <Sidebar v-if="this.$route.path!='/login'"></Sidebar>
    <div id="app" v-if="this.$route.path!='/login'">
      <router-view/>
    </div>
    <login v-if="this.$route.path=='/login'"></login>
  </div>
</template>

<script>

import Sidebar from './Doc/sidebar.vue'
import Header from './Doc/header.vue'
import login from './Module/login'

export default {
  name: 'App',
  components: {
    Header,
    Sidebar,
    login,
  }
}
</script>

<style>

</style>
